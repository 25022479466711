<template>
  <section id="dashboard">
    <v-row>
      <v-col cols="12" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-text class="d-flex align-center">
            <div class="">
              <v-icon size="45" color="primary">
                mdi-account-multiple-outline
              </v-icon>
            </div>
            <div class="ml-5">
              <div class="caption text-center">TOTAL USERS</div>
              <div class="display-1">0</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-text class="d-flex align-center">
            <div class="">
              <v-icon size="45" color="primary"> mdi-book-outline </v-icon>
            </div>
            <div class="ml-5">
              <div class="caption text-center">TOTAL COURSES</div>
              <div class="display-1">0</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <!-- <v-card rounded="lg" elevation="0">
          <v-card-text class="d-flex align-center">
            <div class="">
              <v-icon size="45" color="success">
                mdi-account-multiple-outline
              </v-icon>
            </div>
            <div class="ml-5">
              <div class="caption text-center">TOTAL TEACHERS</div>
              <div class="display-1">0</div>
            </div>
          </v-card-text>
        </v-card> -->
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {};
</script>